import { InjectionToken } from '@angular/core';

interface ITableTreeService {
  expandTree(tree: ITreeContract[]): void;
  collapseTree(tree: ITreeContract[]): void;
}

const ITABLETREE_SERVICE = new InjectionToken<ITableTreeService>('ITableTreeService');

export { ITableTreeService, ITABLETREE_SERVICE };

export interface ITreeContract {
  isOpen?: boolean;
  children?: ITreeContract[];
}
